<template>
  <div class="error-banner">
    <img :src="require(`../assets/images/alert.png`)" />
    {{ message }}
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Banner',
  props: {
    message: String
  }
}
</script>
<style scoped>
.error-banner {
  color: #aa0510;
  background: #fbf3f3;
  font-family: TitilliumWeb-Regular, sans-serif;
  font-weight: bold;
  padding: 10px 15px 10px 15px;
  border-radius: 6px;
  border: 1px solid #aa0510;
  margin-top: 20px;
  font-size: 14px;
}
.error-banner img {
  vertical-align: middle;
}
</style>
