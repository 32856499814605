import { Auth } from 'aws-amplify'
import { WEB_URL } from '../config'
let redirectUrl
export const initializeRedirectUrl = async () => {
  try {
    const searchParams = new URLSearchParams(window.location.search)
    const url = new URL(
      searchParams.get('return_to') ||
        searchParams.get('redirectUri') ||
        sessionStorage.getItem('redirectUrl')
    )
    redirectUrl = url.toString()
  } catch (e) {
    console.error('Error while parsing redirect url', { e })
    redirectUrl = WEB_URL
  }
  // Store url in session storage for link to link movement and reload scenario
  sessionStorage.setItem('redirectUrl', redirectUrl)
  return redirectUrl
}

export async function getAccessToken() {
  const user = await Auth.currentAuthenticatedUser()
  return user?.signInUserSession?.accessToken?.jwtToken
}

export const getRedirectUrl = async (token) => {
  try {
    const url = new URL(redirectUrl)
    if (token) {
      url.searchParams.append('code', token)
      return url.toString()
    } else {
      throw 'User not logged in'
    }
  } catch (e) {
    console.error('Error while parsing redirect url', { e })
    return WEB_URL
  }
}

/**
 * Get application name
 */
export function getApplicationName() {
  const url = redirectUrl ?  new URL(redirectUrl) : undefined
  return url?.host?.startsWith('submaran') ? 'Submaran' : 'Datamaran'
}
