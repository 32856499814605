import { createApp } from 'vue'
import './styles/common.css'
import App from './App.vue'
import router from './router'
import Amplify, { Auth } from 'aws-amplify'
import {
  applyPolyfills,
  defineCustomElements
} from '@aws-amplify/ui-components/loader'
import { AWS_AMPLIFY_CONFIG } from './config'

Amplify.configure(AWS_AMPLIFY_CONFIG)
Auth.configure()
applyPolyfills().then(() => {
  defineCustomElements(window)
})
createApp(App).use(router).mount('#app')
