<template>
  <div class="footer">
    <div class="footer-links tiny-text">
      <img class="gray-logo" src="../assets/images/logo.svg" />
      <span>{{ `© Datamaran, Ltd.` }} </span>
      |
      <span>
        <a href="https://www.datamaran.com/" target="_blank" rel="noopener noreferrer">
          www.datamaran.com
        </a>
      </span>
      |
      <span>
        <a href="https://www.datamaran.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
      </span>
      |
      <span>
        <a href="https://www.datamaran.com/terms-of-use" target="_blank" rel="noopener noreferrer">
          Terms of Use
        </a>
      </span>
      <div class="help-container">
        <i class="ic_help_center_dark" />
        <a href="https://help.datamaran.com/" target="_blank" rel="noopener noreferrer"> Help Center </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  setup() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>

<style>
.footer {
  display: flex;
  justify-content: left;
  padding-left: 5px;
}
.footer-links {
  display: flex;
  position: absolute;
  margin: 10px 0;
  gap: 3px;
  bottom: 0;
  font-family: TitilliumWeb-Regular, sans-serif;
  font-size: 11px;
  font-style: normal;
  align-items: center;
  margin-left: 5px;
}
.help-container {
  margin-left: 5px;
  display: flex;
  align-items: center;
  gap: 3px;
}
i.ic_help_center_dark {
  background-image: url('../icon/ic_help_center_dark.svg');
  background-repeat: no-repeat;
  width: 13px;
  height: 13px;
}
.gray-logo {
  width: 102px;
  margin-right: 5px;
}

a:hover {
  text-decoration: underline;
  text-decoration-color: #007a88;
}
</style>
