<template>
  <div class="main-container">
    <loader v-if="loading"></loader>
    <div class="content">
      <div class="form-container">
        <div class="form">
          <div class="title-container">
            <div class="title">Log in to {{ systemName }}</div>
            <span v-if="envTag" class="env-tag">{{
              envTag.toUpperCase()
            }}</span>
          </div>
          <div class="login-sso" v-if="isSSOEnabled && !requirePassword">
            <button @click="loginViaSSO()" type="button" :disabled="loading">
              Login with sso
            </button>
            <p class="info">OR</p>
          </div>
          <form v-if="!requirePassword" @submit="next" class="email-form">
            <div class="fields">
              <div class="field" :class="message && 'error'">
                <p class="label">Email address</p>
                <input
                  id="email"
                  v-model="email"
                  name="email"
                  class="input"
                  maxlength="100"
                  placeholder="Type here..."
                  :disabled="loading"
                />
              </div>
            </div>
            <div class="action">
              <button id="next-login-step" type="submit" @click="validateEmail">
                Next
              </button>
            </div>
          </form>
          <form v-if="requirePassword" @submit="login">
            <div class="fields">
              <div class="field" :class="message && 'error'">
                <p class="label">Email</p>
                <div class="email-label disabled-label">
                  <label>{{ email }}</label>
                  <a class="change-link" @click="requirePassword = false"
                    >CHANGE</a
                  >
                </div>
                <p class="label">Password</p>
                <input
                  id="password"
                  v-model="password"
                  type="password"
                  name="password"
                  class="input"
                  maxlength="100"
                  :disabled="loading"
                />
              </div>
              <div class="action">
                <button
                  id="signin"
                  :disabled="!password"
                  :class="!password ? 'disabled' : ''"
                  type="submit"
                >
                  Login
                </button>
              </div>
            </div>
            <div class="password-link">
              <router-link class="reset" to="/forgot-password"
                >Forgot password
              </router-link>
            </div>
          </form>
          <Banner :message="message" v-if="message" />
          <div class="personal-data-text">
		Find out more about how Datamaran processes your 
		Personal Data by reading our <a href="https://www.datamaran.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        </div>
        <div class="personal-data-text">
		Find here our <a href="https://www.datamaran.com/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a>
        </div>
	</div>
      </div>
      <LoginImage></LoginImage>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { Auth } from 'aws-amplify'

import Loader from '../components/Loader.vue'
import Banner from '../components/Banner.vue'
import LoginImage from '../components/LoginImage.vue'
import { required, email as emailValidator } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import {
  getAccessToken,
  getApplicationName,
  getRedirectUrl
} from '../helpers/redirection'
import { computed, ref, watchEffect } from 'vue'
import { isSSOEnabled } from '../helpers/validation'

export default {
  name: 'Login',
  components: {
    Loader,
    Banner,
    LoginImage
  },
  setup() {
    const envTag = window.location.hostname.split('.')[0].split('-')[1]
    const email = ref(undefined)
    const password = ref(undefined)
    const message = ref(undefined)
    const requirePassword = ref(false)
    const loading = ref(true)
    const rules = computed(() => ({
      email: {
        required,
        emailValidator
      },
      password: { required }
    }))
    Auth.signOut()
    const v$ = useVuelidate(rules, { email, password })

    async function checkAlreadyLoggedIn() {
      try {
        window.location.href = await getRedirectUrl(await getAccessToken())
      } catch (e) {
        console.error('Error while auto signing in ', { e })
        loading.value = false
      }
    }
    checkAlreadyLoggedIn()

    async function login(e) {
      e.preventDefault()
      loading.value = true
      message.value = undefined
      try {
        await Auth.signIn(email.value, password.value)
        window.location.href = await getRedirectUrl(await getAccessToken())
      } catch (e) {
        console.error('Error while signing in ', { e })
        message.value =
          'Please provide a valid email address and password. If you continue to have issues logging into your account, contact our Support team.'
        loading.value = false
      }
    }
    async function next(e) {
      e.preventDefault()
      loading.value = true
      message.value = undefined
      password.value = ''
      try {
        /*let resp = await fetch(`/api/login/check/${email.value}`)
        if (
          resp.headers.get('content-type')?.indexOf('application/json') !== -1
        ) {
          resp = await resp.json()
          if (resp?.authenticationURL) {
            window.location.href = resp.authenticationURL
            return
          }
        }*/
        requirePassword.value = true
      } catch (e) {
        console.error('Error while signing in ', { e })
        message.value =
          'Please provide a valid email address and password. If you continue to have issues logging into your account, contact our Support team.'
      }
      loading.value = false
    }

    async function validateEmail(e) {
      if (!/^[\w.+-]+@([\w-]+\.)+[\w-]{2,63}$/.test(email.value)) {
        e.preventDefault()
        message.value =
          'Please provide a valid email address. If you continue to have issues logging into your account, contact our Support team.'
      }
    }
    watchEffect(() => {
      if (email.value && password.value) {
        message.value = undefined
      }
    })
    return {
      v$,
      email,
      password,
      requirePassword,
      loading,
      message,
      login,
      next,
      envTag,
      systemName: getApplicationName(),
      validateEmail,
      isSSOEnabled: isSSOEnabled(),
      loginViaSSO: async () => {
        loading.value = true
        await Auth.federatedSignIn({
          provider: 'GoogleInternal'
        })
      }
    }
  }
}
</script>
<style>
.email-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 38px;
  padding-right: 60px;
}
.email-label > label {
  overflow: hidden;
  text-overflow: ellipsis;
}
.email-label > a {
  position: absolute;
  right: 10px;
  color: #007a88;
  font-weight: bold;
  font-size: 12px;
  font-family: TitilliumWeb-Regular;
}
.password-link {
  text-align: start;
  padding-top: 10px;
}
.email-form .action {
  margin-top: 95px;
}
.login-sso {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 10px;
}
.login-sso .info {
  text-align: center !important;
}
.personal-data-text {
  font-size: 10px;
  margin: 15px;
  font-style: italic;
  font-family: TitilliumWeb-Regular;
}
</style>
