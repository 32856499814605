/**
 * Important note - 
 * The Web Client in User Pool should be created without generating a client secret for a web client
 * otherwise it will through a error for not able to verify secret hash
 */
export const AWS_AMPLIFY_CONFIG = {
    Auth: {      
      region: process.env.VUE_APP_AWS_REGION || 'us-east-1',
      userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID, // Pool Id 
      userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_WEB_CLIENT_ID, // User Pool Web Client Id
      oauth: {
        // cognito domain
        domain : process.env.VUE_APP_COGNITO_DOMAIN,
        scope: [
          'phone',
          'email',
          'profile',
          'openid',
          'aws.cognito.signin.user.admin'
        ],
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
    }
  }

export const DEMO=false
export const WEB_URL="https://app.datamaran.com"