<template>
 <div class="media">
    <a :href="hrefLink" target="_blank" rel="noopener">
      <span class="image" :style="inlineStyle" />
    </a>
  </div>
</template>

<script>
import { computed, ref, onMounted } from "vue";

const random = Math.random()
function getUrlImage() {
  if (window.location.href.includes("//login.datamaran.com")) {
    return `https://login.datamaran.com/img/bg.jpg?t=${random}`;
  } else {
    return `https://login-uat.datamaran.com/img/bg.jpg?t=${random}`;
  }
}

async function getHrefImageLink(){
	let result = ''
	try {
		const response = await fetch('api/login_image_url')
		if(response.ok){
			result = await response.json()
		} else {
			throw response
		}
	} catch (e) {
		console.error('Error while getting the image url so default to datamaran URL', e)
		result = 'https://www.datamaran.com/'
	}
	return result
}

export default {
  name: "LoginImage",
  props: {},
  setup() {
    const inlineStyle = computed(() => {
      return {
        backgroundImage: `url(${getUrlImage()})`,
      };
    });
	const hrefLink = ref('https://www.datamaran.com/')
	onMounted(async () => {
			hrefLink.value = await getHrefImageLink()
		})
    return { 
		inlineStyle,
		hrefLink
	};
  },
};
</script>

<style scoped></style>
