<template>
  <router-view v-if="!waitForLogout" />
  <Footer />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
import { Auth } from 'aws-amplify'
import { initializeRedirectUrl } from './helpers/redirection'
import Footer from './views/Footer.vue'
import { hasSSOAuthCode } from './helpers/validation'
import { ref } from 'vue'
export default {
  name: 'App',
  components: { Footer },
  setup() {
    const waitForLogout = ref(!hasSSOAuthCode())
    /**
     * Don't signout if we have sso enabled and a code parameter in url
     * and wait for signout to complete in that case to avoid race condition
     */
    if (waitForLogout.value) {
      // To prevent another redirect on logout by amplify
      localStorage.setItem('amplify-signin-with-hostedUI', 'false')
      Auth.signOut().finally(() => (waitForLogout.value = false))
    }
    initializeRedirectUrl()
    return {
      waitForLogout
    }
  }
}
</script>
