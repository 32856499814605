import { helpers } from '@vuelidate/validators'
import { AWS_AMPLIFY_CONFIG } from '../config'
import { getApplicationName } from './redirection'
export const passwordStrength = helpers.withMessage(
  'Password does not meet character requirements',
  helpers.regex(
    ///^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-_])[A-Za-z\d@$!%*#?&-_]{12,}$/
    /^(?=.{12,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/
  )
)

export const matchRegex = (message, regex) =>
  helpers.withMessage(message, helpers.regex(regex))

export function isValidHttpUrl(string) {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export function isSSOEnabled(){
  return AWS_AMPLIFY_CONFIG?.Auth.oauth?.domain && getApplicationName() !== 'Submaran'
}

export function hasSSOAuthCode(){
  const searchParams = new URLSearchParams(window.location.search)
  return isSSOEnabled() && searchParams.get('code')
}